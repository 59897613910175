import * as React from "react";
import { CATEGORY_DISALLOW_LIST } from "../../../../data/categories";
import { CoinomicsQuery_CryptoCategory } from "../../../../graphql-types";
import { EventCategory } from "../../../utils/analytics";
import CategoryCard from "../../ui-library/cards/category-card/category-card";
import Column from "../../ui-library/column-system/column";
import Row from "../../ui-library/column-system/row";
import Container from "../../ui-library/container/container";
import PageHeading from "../../ui-library/page-heading/page-heading";

interface CategoriesProps {
  categories: Pick<
    CoinomicsQuery_CryptoCategory,
    "id" | "name" | "slug" | "coinCount"
  >[];
}

const Categories: React.FC<CategoriesProps> = ({ categories }) => {
  return (
    <Container>
      <PageHeading
        heading="Categories"
        subHeading="Explore different types of crypto projects"
      />

      <Row>
        {categories
          .filter((c) => !CATEGORY_DISALLOW_LIST.includes(c.id))
          .map((category) => (
            <Column key={category.id} spanLg={4}>
              <CategoryCard
                {...category}
                eventCategory={EventCategory.CategoriesPage}
              />
            </Column>
          ))}
      </Row>
    </Container>
  );
};

export default Categories;
