import { CSSObject } from "@emotion/react";
import { grid } from "../../design-tokens/grid";
import { Colors } from "../../design-tokens/colors";
import { Fonts } from "../../design-tokens/fonts";

const card: CSSObject = {
  display: "flex",
  flexDirection: "column",
  padding: grid(2),
  paddingRight: grid(2.5),
};

const name: CSSObject = {
  minHeight: grid(3.5),
  color: Colors.Black,
  lineHeight: "22px",
  fontWeight: Fonts.Weight.Bold,
  marginBottom: grid(1.5),
};

const coinCount: CSSObject = {
  color: Colors.MidGrey,
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  marginTop: "auto",
  marginBottom: 0,
};

const styles = {
  card,
  coinCount,
  name,
};

export default styles;
