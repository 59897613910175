import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../components/layout/layout";
import Categories from "../components/pages/categories/categories";
import SEO from "../components/seo";
import { CategoriesQuery } from "../../graphql-types";
import { NavTab } from "../components/layout/navigation/navigation";

type CategoriesPageProps = PageProps<CategoriesQuery>;

const CategoriesPage: React.FC<CategoriesPageProps> = ({ data }) => {
  return (
    <Layout activeTab={NavTab.Categories}>
      <SEO
        title="Browse cryptocurrencies by categories"
        description="Explore coins and tokens by categories. Find popular categories and what coins and tokens are in that category."
      />

      <Categories categories={data.coinomicsQuery.cryptoCategories || []} />
    </Layout>
  );
};

export default CategoriesPage;

export const query = graphql`
  query Categories {
    coinomicsQuery {
      cryptoCategories {
        id
        name
        slug
        coinCount
      }
    }
  }
`;
