import * as React from "react";
import { LinkCard } from "../card";
import { buildCategoryUrl } from "../../../../utils/build-url";
import { EventCategory } from "../../../../utils/analytics";
import styles from "./category-card.styles";

interface CategoryCardProps {
  slug: string;
  name: string;
  coinCount: number;
  eventCategory: EventCategory;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  slug,
  name,
  coinCount,
  eventCategory,
}) => {
  return (
    <LinkCard
      href={buildCategoryUrl(slug)}
      css={styles.card}
      analytics={{
        category: eventCategory,
        action: `Click - ${name}`,
        label: name,
      }}
    >
      <div css={styles.name}>{name}</div>

      <div css={styles.coinCount}>{coinCount} projects</div>
    </LinkCard>
  );
};

export default CategoryCard;
