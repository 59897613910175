import * as React from "react";
import { Colors } from "../design-tokens/colors";
import { Fonts } from "../design-tokens/fonts";
import { grid } from "../design-tokens/grid";
import MarginBox from "../margin-box/margin-box";
import Heading from "../text/heading";
import Text from "../text/text";

interface PageHeadingProps {
  heading: string;
  subHeading?: string;
}

const PageHeading: React.FC<PageHeadingProps> = ({ heading, subHeading }) => (
  <MarginBox
    margin={{ desktop: { bottom: grid(4) }, mobile: { bottom: grid(3) } }}
  >
    <Heading component="h1" size="large">
      {heading}
    </Heading>

    {subHeading && (
      <Text
        component="div"
        textSize={14}
        textColor={Colors.MidGrey}
        weight={Fonts.Weight.SemiBold}
        margin={{ top: grid(1) }}
      >
        {subHeading}
      </Text>
    )}
  </MarginBox>
);

export default PageHeading;
